import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "edit-a-channel"
    }}>{`Edit a Channel`}</h1>
    <hr></hr>
    <p>{`To edit a Channel, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and click on Channels from the left side menu options. Choose the Channel you wish to edit. `}</li>
      <li parentName="ol">{`Within the selected Channel, click on the 'Mobile Channel Settings' tab.`}</li>
      <li parentName="ol">{`Provide a name for your Channel in the 'Channel Name' field. This is the name the Channel will appear as while in the Content Management System. `}</li>
      <li parentName="ol">{`Provide the published name in the 'Publish Name' field. This is the name of the library as it will appear in the Neariest Libraries tab within your app.`}</li>
      <li parentName="ol">{`Uploade an image to be used for this library location. `}</li>
      <li parentName="ol">{`Set a status level. If set to 'Active', this location will be displayed in the app. If set to 'Inactive', this location will not display in the app.`}</li>
      <li parentName="ol">{`Set the priority level for this library location. The priority will determine if this location will override the default order and be placed at the the top or near the top of the list. By deault, libraries appear in order of proximity to the app user's GPS location, from closest to farthest. `}
        <ul parentName="li">
          <li parentName="ul">{`If set to 'Silver', the default sorting order will be applied. `}</li>
          <li parentName="ul">{`If set to 'Gold', the library location will be at or near the top. If more than one library has 'Gold' priority, the app will sort these libraries by proximity, from closest to farthest. `}</li>
          <li parentName="ul">{`If set to 'Platinum', the library location will placed at the top of the list. If more than one library has 'Platinum' priority, the app will sort these libraries by proximity, from closest to farthest. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Enable 'Show Opening Hours' if you want to display the hours of operations for this location. Provide the hours of operation in the 24-hour-clock format. To indicate a day whne the library is closed, set the opening and closing times to 00:00.`}</li>
      <li parentName="ol">{`Enter in the library's physcial address in the 'Address' field. This will appear within the app under the library's information page. `}</li>
      <li parentName="ol">{`Enter in the library's phone number in the 'Phone Number' field. This will appear within the app under the library's information page and allow the user to call out once clicked on.`}</li>
      <li parentName="ol">{`Provide the library's website URL in the 'Web Address' field. This will appear within the app under the library's information page. When clicked, the app will open an in-app browser that links to this URL.`}</li>
      <li parentName="ol">{`Provide the library's email address in the 'Email Address' field. This will appear within the app under the library's information page. When selected, the device's default email application opens an email message that is pre-addressed to this address. `}</li>
      <li parentName="ol">{`Set Content View to 'Standard'.`}</li>
      <li parentName="ol">{`Provide a descitption text in the 'Description Text' field box. This captures any additional notes that you would like to include about this location include midday closures, holiday hours, etc. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to save the changes. `}</li>
    </ol>
    <p>{`Related Training Video:
`}<a parentName="p" {...{
        "href": "https://appdocs.sol.us/trainings-creating-channels"
      }}>{`Creating Channels Training Video`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      